import { alpha, styled } from '@mui/material/styles';
import palette from '../../theme/palette';

export type LabelColor = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
type Variant = 'filled' | 'outlined' | 'ghost';

interface Props {
  color: LabelColor,
  variant: Variant,
}


const RootStyle = styled('span')<{ styleProps: {
  color: LabelColor,
  variant: Variant,
} }>(({ theme, styleProps }) => {
  const isLight = theme.palette.mode === 'light';
  const { color, variant } = styleProps;

  const styleFilled = (color: LabelColor) => ({
    color: palette[color].contrastText,
    backgroundColor: palette[color].main
  });

  const styleOutlined = (color: LabelColor) => ({
    color: palette[color].main,
    backgroundColor: 'transparent',
    border: `1px solid ${palette[color].main}`
  });

  const styleGhost = (color: LabelColor) => ({
    color: palette[color][isLight ? 'dark' : 'light'],
    backgroundColor: alpha(palette[color].main, 0.16)
  });

  return {
    height: 22,
    minWidth: 22,
    lineHeight: 0,
    borderRadius: 8,
    cursor: 'default',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    color: palette.grey[800],
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily,
    backgroundColor: palette.grey[300],
    fontWeight: theme.typography.fontWeightBold,

    ...(color !== 'info'
      ? {
        ...(variant === 'filled' && { ...styleFilled(color) }),
        ...(variant === 'outlined' && { ...styleOutlined(color) }),
        ...(variant === 'ghost' && { ...styleGhost(color) })
      }
    : {
        ...(variant === 'outlined' && {
          backgroundColor: 'transparent',
          color: theme.palette.text.primary,
          border: `1px solid ${palette.grey[500_32]}`
        }),
        ...(variant === 'ghost' && {
          color: isLight ? theme.palette.text.secondary : theme.palette.common.white,
          backgroundColor: palette.grey[500_16]
        })
      })
  };
});

// ----------------------------------------------------------------------

export const Label: React.FC<Props> = ({ color = 'info', variant = 'ghost', children, ...other }) => {
  return (
    <RootStyle styleProps={{ color, variant }} {...other}>
      {children}
    </RootStyle>
  );
}