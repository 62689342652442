import * as Yup from 'yup';

export const instanceModalFormSchema = Yup.object({
    name: Yup.string().required('Insira o nome da instância'),
    webhookUrl: Yup.string().required('Insira a url do webhook'),
}).strict();

export const buyInstanceModalFormSchema = Yup.object({
    street: Yup.string().required('Insira a sua rua'),
    number: Yup.string().required('Insira o número da sua casa'),
    district: Yup.string().required('Insira o bairro'),
    zipCode: Yup.string().required('Insira o CEP'),
    complement: Yup.string().optional(),
    cityName: Yup.string().required('Insira a sua cidade'),
    stateInitials: Yup.string().required('Selecione o seu estado'),
    identity: Yup.string().required('Insira o seu CPF ou CNPJ'),
    name: Yup.string().required('Insira o seu nome completo'),
    email: Yup.string().required('Insira o seu e-mail'),
    phone: Yup.string().required('Insira o seu telefone'),
    renewInstances: Yup.array().optional(),
    numberOfInstances: Yup.number().optional(),
}).strict();
