import React, { useState, useEffect } from 'react';
import { When } from 'react-if';
import DetailsIcon from '@mui/icons-material/RemoveRedEye';
import { useSelector } from 'react-redux';
import { Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import Loading from '../../loading';
import NoData from '../../no-data';
import { RootState, store } from '../../../store';
import { PaginatorFactory } from '../../../factory';
import { MoreOptionsList } from '../more-options';
import { INSTANCE_COLUMNS } from '../../../builders/instance-builders';
import { InstanceService } from '../../../services/instance.service';
import { Label } from '../../label';
import { instanceStatusLabelSanitalizer } from '../../../sanitalizers/instance-status-label';

export const InstanceList: React.FC = () => {
    const navigate = useNavigate();
    const { pagination: { total, items }, loading } = useSelector((state: RootState) => state.instance);

    const [pagination, setPagination] = useState<PaginatorFactory<any>>({
      page: 0,
      perPage: 50,
    });
  
    const instanceIsExpired = (expirationDate: Date) => dayjs(expirationDate).isBefore(new Date());

    useEffect(() => {
        InstanceService.fetchMany({ ...pagination, page: pagination.page + 1, filter: {} });
    }, [pagination?.perPage, pagination?.page]);

    return (
        <>
            <Card sx={{ mt: 5 }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow> 
                                {INSTANCE_COLUMNS?.map((column) => (
                                    <TableCell>{column?.headerName}</TableCell>
                                ))}
                                <TableCell>
                                    Ações
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <When condition={!loading?.fetchMany}>
                            <TableBody>
                                {items?.map((instance) => (
                                    <TableRow key={instance?.id}>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box>
                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                        {instance?.name}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {instance?.webhookUrl}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {instance?.key}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {instance?.token}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {instance?.source}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Label
                                                variant={'ghost'}
                                                color={instanceStatusLabelSanitalizer[instance?.status]?.color}
                                            >
                                                {instanceStatusLabelSanitalizer[instance?.status]?.label}
                                            </Label>
                                        </TableCell>
                                        <TableCell>
                                            <Label
                                                variant={'ghost'}
                                                color={instanceIsExpired(instance?.expirationDate) ? 'error' : 'info'}
                                            >
                                                {instance?.expirationDate ? dayjs(instance?.expirationDate).format('DD/MM/YYYY') : 'N/A'}
                                            </Label>
                                        </TableCell>
                                        <TableCell>
                                            <MoreOptionsList
                                                options={[
                                                    {
                                                        icon: <DetailsIcon fontSize="small" />,
                                                        label: 'Visualizar',
                                                        onClick: async () => {
                                                            await InstanceService.fetchByKey(instance?.key, 
                                                                async (isValid, error) => {
                                                                    navigate(`/instances/${instance?.key}`);
                                                                }
                                                            );
                                                        },
                                                    },
                                                ]}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </When>
                    </Table>
                    
                    <When condition={loading?.fetchMany}>
                        <Loading />
                    </When>

                    <When condition={!loading?.fetchMany && items?.length < 1}>
                        <NoData 
                            buttonText='Criar nova instância'
                            emoji={'🤖'}
                            onClick={() => InstanceService.dispatch.toggleModal('create', true)}
                            title={'Nenhuma instância foi encontrada.'}
                            description={'Aparentemente, você ainda não criou nenhuma instância.'}
                        />
                    </When>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={pagination?.perPage}
                    page={pagination?.page}
                    onPageChange={(e, p) => setPagination(pag => ({ ...pag, page: p }))}
                    onRowsPerPageChange={(e) => setPagination(pag => ({ ...pag, perPage: parseInt(e.target.value, 10) }))}
                />
            </Card>
        </>
    )
}