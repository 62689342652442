import { Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { When } from 'react-if';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';
import { Logo } from '../../../components';

export interface LoginLayoutProps {
  children: ReactNode;
}

const LoginLayout: React.FC<LoginLayoutProps> = (props) => {
    const { children } = props;
    const navigate = useNavigate();

    function goHome() {
        navigate('/', { replace: true });
    }

    const theme = useTheme();
    const isSmallOrLess = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <S.Container isSmall={isSmallOrLess}>
            <When condition={isSmallOrLess}>
                <div className="left-container">
               

                </div>
            </When>
            <div className="right-container">{children}</div>
        </S.Container>
    );
};

export default LoginLayout;
