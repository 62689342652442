import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Grid, Paper, Card } from '@mui/material';
import { alpha } from '@mui/material/styles';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import CheckIcon from '@mui/icons-material/Check';
import DesktopMacIcon from '@mui/icons-material/SmartphoneOutlined';

import { RootState } from '../../store';
import { InstanceService } from '../../services/instance.service';

export const InstancesNumbers: React.FC = () => {
    const { authUser } = useSelector((state: RootState) => state.user);
    const { report } = useSelector((state: RootState) => state.instance);

    useEffect(() => {
        InstanceService.getMineInstancesReport();
    }, [authUser]);

    if (!report) return null;

    return (
        <Grid container display="flex" flexDirection="row" spacing={1} sx={{ mt: 2 }}>
            <Grid item md={4} sm={12} width={'100%'}>
                <Paper 
                    elevation={3}
                    variant="outlined" 
                    square 
                    sx={{ 
                        bgcolor: 'background.default',
                        borderRadius: 1,
                    }}
                >
                    <Card sx={{ borderRadius: 1 }}>
                        <Box sx={{ p: 2 }}>
                            <Grid container display='flex' flexDirection={'row'} alignItems='center'>
                                <Grid item md={2} sm={2} xs={2}>
                                    <Box display={'flex'} borderRadius={1} width={'100%'} height={72} sx={{ background: alpha('#3366FF', 0.16) }} alignItems={'center'} justifyContent={'center'}>
                                        <DesktopMacIcon sx={{ height: 32, width: 32, color: alpha('#3366FF', 1) }} />
                                    </Box>
                                </Grid>
                                <Grid item md={8} sm={9} xs={9} sx={{ ml: 2 }}>
                                    <Typography component="div" variant="subtitle2">
                                        Instâncias cadastradas
                                    </Typography>
                                    <Typography component="div" variant="h5" fontSize={18} fontWeight={700}>
                                        {report?.total}/{authUser?.maxInstances}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Paper>
            </Grid>
            <Grid item md={4} sm={12} width={'100%'}>
                <Paper 
                    elevation={3}
                    variant="outlined" 
                    square 
                    sx={{ 
                        bgcolor: 'background.default',
                        borderRadius: 1,
                    }}
                >
                    <Card sx={{ borderRadius: 1 }}>
                        <Box sx={{ p: 2 }}>
                            <Grid container display='flex' flexDirection={'row'} alignItems='center'>
                                <Grid item md={2} sm={2} xs={2}>
                                    <Box display={'flex'} borderRadius={1} width={'100%'} height={72} sx={{ background: alpha('#25b660', 0.16) }} alignItems={'center'} justifyContent={'center'}>
                                        <CheckIcon sx={{ height: 32, width: 32, color: alpha('#25b660', 1) }} />
                                    </Box>
                                </Grid>
                                <Grid item md={8} sm={9} xs={9} sx={{ ml: 2 }}>
                                    <Typography component="div" variant="subtitle2">
                                        Instâncias conectadas
                                    </Typography>
                                    <Typography component="div" variant="h5" fontSize={18} fontWeight={700}>
                                        {report?.connected?.length}/{report?.total}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Paper>
            </Grid>
            <Grid item md={4} sm={12} width={'100%'}>
                <Paper 
                    elevation={3}
                    variant="outlined" 
                    square 
                    sx={{ 
                        bgcolor: 'background.default',
                        borderRadius: 1,
                    }}
                >
                    <Card sx={{ borderRadius: 1 }}>
                        <Box sx={{ p: 2 }}>
                            <Grid container display='flex' flexDirection={'row'} alignItems='center'>
                                <Grid item md={2} sm={2} xs={2}>
                                    <Box display={'flex'} borderRadius={1} width={'100%'} height={72} sx={{ background: alpha('#FF4842', 0.16) }} alignItems={'center'} justifyContent={'center'}>
                                        <PowerSettingsNewIcon sx={{ height: 32, width: 32, color: alpha('#FF4842', 1) }} />
                                    </Box>
                                </Grid>
                                <Grid item md={8} sm={9} xs={9} sx={{ ml: 2 }}>
                                    <Typography component="div" variant="subtitle2">
                                        Instâncias desconectadas
                                    </Typography>
                                    <Typography component="div" variant="h5" fontSize={18} fontWeight={700}>
                                        {report?.disconnected?.length}/{report?.total}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Paper>
            </Grid>
        </Grid>
    )
}