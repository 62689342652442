import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

import { UserService } from '../../../services/user.service';

export default function LoginForm() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup
            .string()
            .email('E-mail incorreto')
            .required('Informe o seu e-mail'),
        password: Yup
            .string()
            .required('Informe a sua senha')
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            remember: true
        },
        validationSchema: LoginSchema,
        onSubmit: async (values) => {
            await UserService.login(values.email, values.password, (isValid, msg) => {
                if (!isValid) {
                    toast(
                        msg,
                        {
                            autoClose: 1500,
                            type: 'error',
                        }
                    );
                    return
                }
        
                toast(
                    'Login realizado com sucesso!',
                    {
                        autoClose: 1500,
                        type: 'success',
                    },
                );

                setTimeout(() => {
                    navigate('/dashboard');
                }, 500);
                    
            });
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        label="Seu email"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label="Sua senha"
                        {...getFieldProps('password')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleShowPassword} edge="end">
                                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                    <FormControlLabel
                        control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
                        label="Lembrar me"
                    />

                    {/**<Link component={RouterLink} variant="subtitle2" to="/recover-password">
                        Esqueceu a senha?
                    </Link> */}
                </Stack>

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Login
                </LoadingButton>
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="text"
                    sx={{ mt: 1 }}
                    onClick={() => navigate('/register')}
                >
                    Criar conta
                </LoadingButton>
            </Form>
        </FormikProvider>
    );
}