import { InputForm } from "../types";

export const INSTANCE_COLUMNS = [
    {
        field: 'name',
        headerName: 'NOME',
        width: 250,
    },
    {
        field: 'webhookUrl',
        headerName: 'URL DO WEBHOOK',
        width: 250,
    },
    {
        field: 'key',
        headerName: 'KEY',
        width: 100,
    },
    {
        field: 'token',
        headerName: 'TOKEN',
        width: 100,
    },
    {
        field: 'source',
        headerName: 'Servidor (source)',
        width: 100,
    },
    {
        field: 'status',
        headerName: 'STATUS',
        width: 250,
    },
    {
        field: 'expirationDate',
        headerName: 'Data de expiração',
        width: 250,
    },
];


export const INSTANCE_FORM_INPUTS: InputForm[] = [
    {
        label: 'Nome da instância',
        id: 'name',
        type: 'text',
        required: true,
        placeholder: 'Insira o nome da instância',
        grid: {
            xs: 12,
            sm: 12,
            md: 12,
        },
    },
    {
        label: 'Url do webhook',
        id: 'webhookUrl',
        type: 'text',
        required: true,
        placeholder: 'Insira o url do webhook',
        grid: {
            xs: 12,
            sm: 12,
            md: 12,
        },
    },
];

export const BUY_INSTANCE_FORM_INPUTS_PERSONAL: InputForm[] = [
    {
        label: 'Nome Completo',
        id: 'name',
        type: 'text',
        required: true,
        placeholder: 'Insira o seu nome completo',
        grid: {
            xs: 12,
            sm: 12,
            md: 6,
        },
    },
    {
        label: 'CPF/CNPJ',
        id: 'identity',
        type: 'text',
        required: true,
        placeholder: 'Insira o seu CPF ou CNPJ',
        grid: {
            xs: 12,
            sm: 12,
            md: 6,
        },
        //mask: '999.999.999-99' || '99.999.999/9999-99',
    },
    {
        label: 'E-mail',
        id: 'email',
        type: 'text',
        required: true,
        placeholder: 'Insira o seu e-mail',
        grid: {
            xs: 12,
            sm: 12,
            md: 6,
        },
    },
    {
        label: 'Telefone',
        id: 'phone',
        type: 'text',
        required: true,
        placeholder: 'Insira o seu telefone',
        grid: {
            xs: 12,
            sm: 12,
            md: 6,
        },
        mask: '(99) 99999-9999',
    },
];

export const BUY_INSTANCE_FORM_INPUTS_ADDRESS: InputForm[] = [
    {
        label: 'CEP',
        id: 'zipCode',
        type: 'text',
        required: true,
        placeholder: 'Insira o CEP',
        grid: {
            xs: 12,
            sm: 12,
            md: 12,
        },
        mask: '99999-999',
    },
    {
        label: 'Rua',
        id: 'street',
        type: 'text',
        required: true,
        placeholder: 'Insira a rua',
        grid: {
            xs: 12,
            sm: 12,
            md: 6,
        },
    },
    {
        label: 'Número',
        id: 'number',
        type: 'text',
        required: true,
        placeholder: 'Insira o número da rua',
        grid: {
            xs: 12,
            sm: 12,
            md: 6,
        },
    },
    {
        label: 'Bairro',
        id: 'district',
        type: 'text',
        required: true,
        placeholder: 'Insira o bairro',
        grid: {
            xs: 12,
            sm: 12,
            md: 6,
        },
    },
    {
        label: 'Complemento',
        id: 'complement',
        type: 'text',
        required: false,
        placeholder: 'Insira o complemento',
        grid: {
            xs: 12,
            sm: 12,
            md: 6,
        },
    },
    {
        label: 'Cidade',
        id: 'cityName',
        type: 'text',
        required: true,
        placeholder: 'Insira a cidade',
        grid: {
            xs: 12,
            sm: 12,
            md: 6,
        },
    },
    {
        label: 'Estado',
        id: 'stateInitials',
        type: 'select',
        required: true,
        placeholder: 'Insira a sigla do seu estado',
        grid: {
            xs: 12,
            sm: 12,
            md: 6,
        },
        options: [
            {
                value: 'AC',
                label: 'Acre',
            }, {
                value: 'AL',
                label: 'Alagoas',
            }, {
                value: 'AP',
                label: 'Amapá',
            }, {
                value: 'AM',
                label: 'Amazonas',
            }, {
                value: 'BA',
                label: 'Bahia',
            }, {
                value: 'CE',
                label: 'Ceará',
            }, {
                value: 'DF',
                label: 'Distrito Federal',
            }, {
                value: 'ES',
                label: 'Espírito Santo',
            }, {
                value: 'GO',
                label: 'Goiás',
            }, {
                value: 'MA',
                label: 'Maranhão',
            }, {
                value: 'MT',
                label: 'Mato Grosso',
            }, {
                value: 'MS',
                label: 'Mato Grosso do Sul',
            }, {
                value: 'MG',
                label: 'Minas Gerais',
            }, {
                value: 'PA',
                label: 'Pará',
            }, {
                value: 'PB',
                label: 'Paraíba',
            }, {
                value: 'PR',
                label: 'Paraná',
            }, {
                value: 'PE',
                label: 'Pernambuco',
            }, {
                value: 'PI',
                label: 'Piauí',
            }, {
                value: 'RJ',
                label: 'Rio de Janeiro',
            }, {
                value: 'RN',
                label: 'Rio Grande do Norte',
            }, {
                value: 'RS',
                label: 'Rio Grande do Sul',
            }, {
                value: 'RO',
                label: 'Rondônia',
            }, {
                value: 'RR',
                label: 'Roraima',
            }, {
                value: 'SC',
                label: 'Santa Catarina',
            }, {
                value: 'SP',
                label: 'São Paulo',
            }, {
                value: 'SE',
                label: 'Sergipe',
            }, {
                value: 'TO',
                label: 'Tocantins',
            },
        ],
    },
];
