import { useEffect } from 'react';
import { Container, Stack, Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import { Page } from '../../components';
import HeaderBreadcrumbs from '../../components/header-breadcrumbs';
import { InstanceMetrics } from '../../components/instance-metrics';
import { InstancesNumbers } from '../../components/instances-numbers';
import MetricsByType from '../../components/metrics-by-type';
import { InstanceService } from '../../services/instance.service';
import { RootState } from '../../store';
import MessagesByTypeChart from '../../components/messages-by-type-chart';

const Home = ({  }) => {
  const { authUser } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    InstanceService.fetchMetrics();
  }, []);
  
  return (
    <Page title="Zapfy - Dashboard">
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <HeaderBreadcrumbs
              heading="Dashboard"
              links={[
                {
                  name: `Olá ${authUser?.fullName}, bem vindo de volta! Aqui está os indicadores sobre a utilização de suas instâncias.`,
                  href: '/dashboard',
                },
              ]}
              activeLast={false}
          />
        </Stack>
        
        <InstancesNumbers />
        <InstanceMetrics />

        <Grid container display="flex" flexDirection="row" spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} md={8}>
            <MessagesByTypeChart />
          </Grid>
          <Grid item xs={12} md={4}>
            <MetricsByType />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default Home
