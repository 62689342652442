import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Backdrop, Box } from '@mui/material';

import DashboardNavbar from './navbar';
import DashboardSidebar from './sidebar';
import { RootState } from '../../store';
import { Loading } from '../../components';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import { InstanceFormModal } from '../../components/forms/instance/instance-modal-form'

export const HEADER = {
    MOBILE_HEIGHT: 64,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 92,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};
  
export const NAVBAR = {
    BASE_WIDTH: 260,
    DASHBOARD_WIDTH: 280,
    DASHBOARD_COLLAPSE_WIDTH: 88,
    //
    DASHBOARD_ITEM_ROOT_HEIGHT: 48,
    DASHBOARD_ITEM_SUB_HEIGHT: 40,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};
  
export const ICON = {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20,
};

const MainStyle = styled('main', {
    shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ theme }) => ({
    flexGrow: 1,
    paddingTop: HEADER.MOBILE_HEIGHT + 24,
    paddingBottom: HEADER.MOBILE_HEIGHT + 24,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
      paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
      width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
      transition: theme.transitions.create('margin-left', {
        duration: theme.transitions.duration.shorter,
      }),
    },
}));

interface Props {
};

const DashboardLayout: React.FC<Props> = () => {
    const { modal } = useSelector((state: RootState) => state.instance);
    const { collapseClick } = useCollapseDrawer();
    const [open, setOpen] = useState(false);

    return (
        <>
            <Box
                sx={{
                    display: { lg: 'flex' },
                    minHeight: { lg: 1 },
                }}
            >
                <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
                <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
                <MainStyle
                    sx={{
                        ...(collapseClick && {
                            marginLeft: '102px'
                        })
                    }}
                >
                    <Outlet />
                </MainStyle>
            </Box>

            {(modal?.create || modal?.edit) && <InstanceFormModal />}
        </>
    );
}

export default DashboardLayout;