import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Box, Dialog, DialogActions, DialogContent, Grid, IconButton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";

import { InstanceService } from '../../../services/instance.service';
import { INSTANCE_FORM_INPUTS } from '../../../builders/instance-builders';
import { RootState } from '../../../store';
import FormBuilder from '../../form-builder';
import { instanceModalFormSchema } from '../../../schemas/instance-modal-form.schema';

interface Props {
}

export const InstanceFormModal: React.FC<Props> = () => {
    const theme = useTheme();

    const { modal, loading, instance } = useSelector((state: RootState) => state.instance);

    const isFromEdit = modal?.edit;

    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    const formik = useFormik<Yup.InferType<typeof instanceModalFormSchema>>({
        initialValues: {
            name: '',
            webhookUrl: '',
        },
        validationSchema: instanceModalFormSchema,
        onSubmit: async (values) => {
            if (!isFromEdit) {
                await InstanceService.create(values?.name, values?.webhookUrl, (isValid, error) => {
                    formik.resetForm();
                    InstanceService.dispatch.toggleModal('create', false);
                    
                    if (!isValid) {
                        toast(
                            error,
                            {
                                autoClose: 1500,
                                type: 'error',
                            }
                        );
                        return
                    }
    
                    toast(
                        'Instância criada com sucesso!',
                        {
                            autoClose: 1500,
                            type: 'success',
                        },
                    );
                }); 
            } else {
                await InstanceService.edit(values?.name, values?.webhookUrl, instance?.key, instance?.token, instance?.source!, (isValid, error) => {
                    formik.resetForm();
                    InstanceService.dispatch.toggleModal('edit', false);
                    
                    if (!isValid) {
                        toast(
                            error,
                            {
                                autoClose: 1500,
                                type: 'error',
                            }
                        );
                        return
                    }
    
                    toast(
                        'Instância alterada com sucesso!',
                        {
                            autoClose: 1500,
                            type: 'success',
                        },
                    );
                }); 
            }
        }
    });

    useEffect(() => {
        if (isFromEdit) {
            formik.setValues({
                ...instance,
            });
        }
    }, [modal?.edit]);

    const { 
        errors,
        touched,
        values,
        setFieldValue,
        handleSubmit,
        getFieldProps,
    } = formik;

    const onClose = () => {
        formik.resetForm();
        
        InstanceService.dispatch.toggleModal(isFromEdit ? 'edit' : 'create', false);
    }

    return (
        <Dialog 
            maxWidth={'sm'}
            open={isFromEdit ? modal?.edit : modal?.create} 
            scroll={'paper'}
            fullScreen={smallScreen}
            onClose={onClose}
        >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography sx={{ ml: 3, mb: 2, mt: 2}} fontSize={18} fontWeight={400} color={'text.default'}>
                        {isFromEdit ? 'Editar instância' : 'Criar nova instância'}
                    </Typography>
                    <Tooltip title="Fechar formulário">
                        <IconButton
                            onClick={onClose}
                            component="span"
                            sx={{ mr: 2, height: 30, width: 30, alignSelf: 'center' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <DialogContent dividers>
                        <Grid container spacing={1}>
                            <FormBuilder
                                errors={errors}
                                touched={touched}
                                values={values}
                                setFieldValue={setFieldValue}
                                inputs={[...INSTANCE_FORM_INPUTS]}
                                getFieldProps={getFieldProps}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ borderTop: 1, borderColor: 'divider', justifyContent: 'space-between', p: 2 }}>
                        <LoadingButton 
                            variant="contained"
                            type="submit"
                            fullWidth
                            loading={loading?.create || loading?.update}
                        >
                            {isFromEdit ? 'Editar instância' : 'Criar instância'}
                        </LoadingButton>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}