import { useState } from 'react';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { Box, Card, Grid, Typography, Chip } from '@mui/material';

import { fNumber } from '../../utils/formatNumber';
import BaseOptionChart from '../base-option-chart';
import { RootState } from '../../store';

export default function MessagesByTypeChart() {
    const { metrics } = useSelector((state: RootState) => state.instance);
    const [type, setType] = useState('sent');

    const types = [
        {
            value: 'sent',
            label: 'Enviadas',
        },
        {
            value: 'received',
            label: 'Recebidas',
        },
    ];

    const chartLabels = [
        'Textos',
        'Opções',
        'Áudios',
        'Vídeos',
        'Botões',
        'Contatos',
        'Documentos',
        'Imagens',
        'Links',
        'Localizações',
        'Stickers',
    ];

    const chartSeries = type === 'sent' ? [
        metrics?.resumeMetrics?.textMsgSent ?? 0,
        metrics?.resumeMetrics?.listMsgSent ?? 0,
        0,
        0,
        metrics?.resumeMetrics?.buttonsMsgSent ?? 0,
        0,
        0,
        0,
        0,
        0,
        0,
    ] : [
        metrics?.resumeMetrics?.textMsgReceived ?? 0,
        metrics?.resumeMetrics?.listMsgReceived ?? 0,
        metrics?.resumeMetrics?.audioMsgReceived ?? 0,
        metrics?.resumeMetrics?.videoMsgReceived,
        metrics?.resumeMetrics?.buttonsMsgReceived,
        metrics?.resumeMetrics?.contactMsgReceived ?? 0,
        metrics?.resumeMetrics?.documentMsgReceived,
        metrics?.resumeMetrics?.imagemMsgReceived,
        0,
        metrics?.resumeMetrics?.locationMsgReceived,
        metrics?.resumeMetrics?.stickerMsgReceived ?? 0,
    ]

    const chartOptions = merge(BaseOptionChart(), {
        tooltip: {
            marker: { show: false },
            y: {
                formatter: (seriesName: number) => fNumber(seriesName),
                title: {
                    formatter: () => '',
                },
            },
            },
            plotOptions: {
                bar: { horizontal: true, barHeight: '28%', borderRadius: 2 },
            },
            xaxis: {
                categories: chartLabels,
        },
    });

    return (
        <Card sx={{ p: 2 }}>
             <Grid container display="flex" flexDirection="row" spacing={1}>
                <Grid item md={8} width={'100%'}>
                    <Typography fontWeight={600} color="text.secondary">
                        Métricas de mensagens
                    </Typography>
                </Grid>
                <Grid item md={4} width={'100%'}>
                    <Grid container display="flex" flexDirection="row">
                        {types?.map((pe) => (
                            <Chip 
                                key={`chip-${pe?.value}-message-by-type`} 
                                size={'small'} 
                                label={pe?.label}
                                onClick={() => setType(pe?.value)}
                                sx={{ mr: 0.5, mt: 0.3 }}
                                variant={type === pe?.value ? 'filled' : 'outlined'} 
                            />
                        ))}
                    </Grid>
                </Grid>
            </Grid>

            <Box sx={{ mx: 3 }} dir="ltr">
                <ReactApexChart type="bar" series={[{ data: chartSeries }]} options={chartOptions as ApexCharts.ApexOptions} height={364} />
            </Box>
        </Card>
    );
}
