import { Stack, Box, Typography } from '@mui/material';

import {
    LoginForm, Logo,
} from '../../../components';
import LoginLayout from '../layout/auth-layout';
import * as S from '../styles';

const Login: React.FC = () => {
    return (
        <LoginLayout>
            <S.LoginContainer>
                <Stack direction="row" alignItems="center">
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h5">Olá, bem vindo de volta!</Typography>
                        <Typography color="textSecondary" mt="10px" variant="subtitle2">
                            Digite as suas credenciais abaixo para acessar o nosso painel
                        </Typography>
                    </Box>
                </Stack>
                
                <S.FormContainer>
                    <LoginForm />
                </S.FormContainer>
            </S.LoginContainer>
        </LoginLayout>
    )
}

export default Login;