import type { RootModel } from '.';
import { createModel } from "@rematch/core";

import { PaginationFactory } from '../factory';
import { InstanceTypes } from '../types';

export interface InstanceStoreProps {
    instance: InstanceTypes.Model,
    state: string,
    pagination: PaginationFactory<InstanceTypes.Model>,
    qrCode: string,
    modal: {
        edit: boolean,
        create: boolean,
        buy: boolean,
        renew: boolean,
    },
    report: {
        total: number,
        connected: InstanceTypes.Model[],
        disconnected: InstanceTypes.Model[],
        updatedAt: Date,
    }, 
    metrics: {
        items: InstanceTypes.Metric[],
        resumeMetrics: InstanceTypes.ResumeMetric,
    },
    loading: {
        fetchById: boolean,
        fetchMany: boolean,
        fetchQr: boolean,
        destroy: boolean,
        create: boolean,
        update: boolean,
        logout: boolean,
        getMineInstancesReport: false,
        fetchMetrics: boolean,
    },
};
export const instance = createModel<RootModel>()({
    state: {
        state: '',
        instance: {} as InstanceTypes.Model,
        qrCode: '',
        pagination: {
            items: [],
            page: 1,
            perPage: 15,
            total: 0,
        } as PaginationFactory<InstanceTypes.Model>,
        modal: {
            edit: false,
            create: false,
            buy: false,
            renew: false,
        },
        metrics: {
            items: [] as InstanceTypes.Metric[],
            resumeMetrics: {} as InstanceTypes.ResumeMetric,
        },
        loading: {
            fetchById: false,
            fetchMany: false,
            destroy: false,
            create: false,
            fetchQr: false,
            update: false,
            logout: false,
            getMineInstancesReport: false,
            fetchMetrics: false,
        },
        report: {} as InstanceStoreProps['report'],
    },
    reducers: {
        setMetric: (
            store,
            metrics: InstanceStoreProps['metrics']
        ) => {
            store.metrics = metrics;
        },
        setReport: (
            store,
            report: InstanceStoreProps['report']
        ) => {
            store.report = report;
        },
        setState: (
            store, 
            state: string
        ) => {
            store.state = String(state);
        },
        setQr: (
            store, 
            qr: string
        ) => {
            store.qrCode = qr;
        },
        set: (
            store,
            instance: InstanceTypes.Model,
        ) => {
            store.instance = instance;
        },
        add: (
            store,
            instance: InstanceTypes.Model,
        ) => {
            store.pagination.items.push(instance);
        },
        eraseQr: (
            store
        ) => {
            store.qrCode = '';
            store.state = '';
        },
        toggleLoading: (
            store, prop: keyof InstanceStoreProps['loading'], v: boolean) => {
                store.loading[prop] = v;
            },
        toggleModal: (
            store, prop: keyof InstanceStoreProps['modal'], v: boolean) => {
                store.modal[prop] = v;
            },
        setPagination: 
            (store, pagination: PaginationFactory<any>) => {
                store.pagination = pagination;
            },
    },
});

