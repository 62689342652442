
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, Card, Grid, Typography, Chip } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../store';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  fontSize: 14,
}));

export default function MetricsByType() {
    const { metrics } = useSelector((state: RootState) => state.instance);
    const [type, setType] = useState('sent');

    const types = [
        {
            value: 'sent',
            label: 'Enviadas',
        },
        {
            value: 'received',
            label: 'Recebidas',
        },
    ];

    return (
        <Card sx={{ p: 2 }}>
            <Grid container display="flex" flexDirection="row" spacing={1}>
                <Grid item md={6} width={'100%'}>
                    <Typography fontWeight={600} color="text.secondary">
                        Métricas de mensagens
                    </Typography>
                </Grid>
                <Grid item md={6} width={'100%'}>
                    <Grid container display="flex" flexDirection="row">
                        {types?.map((pe) => (
                            <Chip 
                                key={`chip-${pe?.value}`} 
                                size={'small'} 
                                label={pe?.label}
                                onClick={() => setType(pe?.value)}
                                sx={{ mr: 0.5, mt: 0.3 }}
                                variant={type === pe?.value ? 'filled' : 'outlined'} 
                            />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            
            <Stack spacing={2} mt={2}>
                {[
                    
                    {
                        type: 'Textos',
                        count: type === 'sent' ? metrics?.resumeMetrics?.textMsgSent ?? 0 : metrics?.resumeMetrics?.textMsgReceived ?? 0,
                    },
                    {
                        type: 'Áudios',
                        count: type === 'sent' ? 0 : metrics?.resumeMetrics?.audioMsgReceived ?? 0,
                    },
                    {
                        type: 'Vídeos',
                        count: type === 'sent' ? 0 : metrics?.resumeMetrics?.videoMsgReceived ?? 0,
                    },
                    {
                        type: 'Botões',
                        count: type === 'sent' ? metrics?.resumeMetrics?.buttonsMsgSent ?? 0 : metrics?.resumeMetrics?.buttonsMsgReceived ?? 0,
                    },
                    {
                        type: 'Contatos',
                        count: type === 'sent' ? 0 : metrics?.resumeMetrics?.contactMsgReceived ?? 0,
                    },
                    {
                        type: 'Documentos',
                        count: type === 'sent' ? 0 : metrics?.resumeMetrics?.documentMsgReceived ?? 0,
                    },
                    {
                        type: 'Imagens',
                        count: type === 'sent' ? 0 : metrics?.resumeMetrics?.imagemMsgReceived ?? 0,
                    },
                    {
                        type: 'Links',
                        count: type === 'sent' ? 0 : 0,
                    },
                    {
                        type: 'Localizações',
                        count: type === 'sent' ? metrics?.resumeMetrics?.locationMsgSent ?? 0 : metrics?.resumeMetrics?.locationMsgReceived ?? 0,
                    },
                    {
                        type: 'Opções',
                        count: type === 'sent' ? metrics?.resumeMetrics?.listMsgSent ?? 0 : metrics?.resumeMetrics?.listMsgReceived ?? 0,
                    },
                    {
                        type: 'Stickers',
                        count: type === 'sent' ? 0 : metrics?.resumeMetrics?.stickerMsgReceived ?? 0,
                    },
                ]?.map(op => <Item {...op} /> )}
            </Stack>
        </Card>
    );
}

interface ItemProps {
    type: string,
    count: number,
}

function Item({ type, count }: ItemProps) {
    return (
        <Stack direction="row" alignItems="center" spacing={2}>

            <Box sx={{ flexGrow: 1 }}>
                <Typography variant="body2">{type}</Typography>
            </Box>

            <IconWrapperStyle
                sx={{ 
                    color: 'primary.main',
                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                }}
            >
                {count}
            </IconWrapperStyle>
        </Stack>
    );
}
