import { IconButton, ListItemIcon, Menu, MenuItem, MenuList, Tooltip, Typography } from '@mui/material';
import React, { ReactNode, useRef, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface Option {
    onClick(): void,
    label: string,
    icon: ReactNode,
};

interface Props {
    options: Option[],
};

export const MoreOptionsList: React.FC<Props> = ({ options }) => {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
           <Tooltip title={'Mais opções'}>
                <IconButton aria-label={'Opções do contato'} ref={ref} onClick={() => setIsOpen(true)}>
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>
            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1.5,
                        width: 130,
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuList>
                    {options?.map((op) => (
                        <MenuItem key={`more-option-${op?.label}`} onClick={op?.onClick}>
                            <ListItemIcon>
                                {op?.icon}
                            </ListItemIcon>
                            <Typography variant="body2" color="text.secondary" sx={{ ml: -2.5 }}>
                                {op?.label}
                            </Typography>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </>
    )
}