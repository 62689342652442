import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ReactInputMask from 'react-input-mask';
import TagManager from 'react-gtm-module';
// @ts-ignore
import gtag from 'ga-gtag';

import { UserService } from '../../../services/user.service';

export default function RegisterForm() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPass, setShowRepeatPass] = useState(false);

    const RegisterSchema = Yup.object().shape({
        fullName: Yup
            .string()
            .min(2, 'Nome muito pequeno')
            .max(50, 'Nome muito grande')
            .required('Informe o seu nome completo'),
        phone: Yup.string()
            .required('Informe o seu número de telefone'),
        email: Yup
            .string()
            .email('Email incorreto')
            .required('Informe o seu e-mail'),
        password: Yup
            .string()
            .oneOf([Yup.ref('repeatPassword'), null], 'As senhas não coincidem')
            .required('Insira a sua senha'),
        repeatPassword: Yup
            .string()
            .oneOf([Yup.ref('password'), null], 'As senhas não coincidem')
            .required('Repita a sua senha'),
    });

    const formik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            password: '',
            repeatPassword: '',
            phone: '',
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values) => {
            await UserService.create({ 
                fullName: values?.fullName,
                email: values?.email,
                password: values?.password,
                phone: `+55 ${values?.phone}`,
            }, (isValid, msg) => {
                if (!isValid) {
                    toast(
                        msg,
                        {
                            autoClose: 1500,
                            type: 'error',
                        }
                    );
                    return
                }

                TagManager.dataLayer({
                    dataLayer: {
                        event: 'sign_up',
                        user: {
                            data: {
                                name: values?.fullName,
                                email: values?.email,
                                phone: values?.phone,
                            },
                        },
                    }
                });

                toast(
                    'Cadastro realizado com sucesso!',
                    {
                        autoClose: 1500,
                        type: 'success',
                    },
                );
                
                navigate('/dashboard');
                UserService.dispatch.setFirstLogin(true);
            });
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        label="Nome completo"
                        required
                        {...getFieldProps('fullName')}
                        error={Boolean(touched.fullName && errors.fullName)}
                        helperText={touched.fullName && errors.fullName}
                    />

                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        required
                        label="Email"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    <ReactInputMask mask={'(99) 9 9999-9999'} {...getFieldProps('phone')}>
                        {() =>   
                            <TextField 
                                type={'text'}
                                fullWidth
                                placeholder={'Informe o seu número do WhatsApp'}
                                required={true}
                                error={Boolean(touched.phone && errors.phone)}
                                helperText={touched.phone && errors.phone}
                                {...getFieldProps('phone')}
                            />
                        }
                    </ReactInputMask>

                    <TextField
                        required
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label="Senha"
                        {...getFieldProps('password')}
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                            </InputAdornment>
                        )
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />

                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        required
                        type={showRepeatPass ? 'text' : 'password'}
                        label="Repetir Senha"
                        {...getFieldProps('repeatPassword')}
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end" onClick={() => setShowRepeatPass((prev) => !prev)}>
                                    <Icon icon={showRepeatPass ? eyeFill : eyeOffFill} />
                                </IconButton>
                            </InputAdornment>
                        )
                        }}
                        error={Boolean(touched.repeatPassword && errors.repeatPassword)}
                        helperText={touched.repeatPassword && errors.repeatPassword}
                    />

                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        Cadastrar
                    </LoadingButton>
                    <LoadingButton
                        fullWidth
                        size="large"
                        variant="text"
                        onClick={() => navigate('/')}
                    >
                        Voltar
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}