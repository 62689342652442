import { Button, Container, Stack } from '@mui/material';
import React from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';

import { Page } from '../../../components';
import HeaderBreadcrumbs from '../../../components/header-breadcrumbs';
import { InstanceService } from '../../../services/instance.service';
import { InstanceList } from '../../../components/lists/instance/instance-list';
import { InstancesNumbers } from '../../../components/instances-numbers';

export const InstanceListScreen: React.FC = () => {
    return (
        <Page title="Zapfy - Minhas instâncias">
            <Container maxWidth={false}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <HeaderBreadcrumbs
                        heading="Minhas instâncias"
                        links={[
                                {
                                name: 'dashboard',
                                href: '/dashboard',
                            },
                            {
                                name: 'instâncias',
                                href: '/instances'
                            },
                        ]}
                        activeLast={false}
                    />
                    {/**<Button
                        variant="contained"
                        onClick={() => {
                            InstanceService.dispatch.toggleModal('renew', true);
                        }}
                        startIcon={<Icon icon={plusFill} />}
                    >
                        Renovar Instâncias
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            InstanceService.dispatch.toggleModal('buy', true);
                        }}
                        startIcon={<Icon icon={plusFill} />}
                    >
                        Comprar Instâncias
                    </Button> */}
                    <Button
                        variant="contained"
                        onClick={() => {
                            InstanceService.dispatch.toggleModal('create', true);
                        }}
                        startIcon={<Icon icon={plusFill} />}
                    >
                        Adicionar
                    </Button>
                </Stack>
                <InstancesNumbers />
                <InstanceList />
            </Container>
        </Page>
    )
}