import {
  Button,
  ButtonProps,
  styled as styledMui,
  TextField,
  TextFieldProps,
} from '@mui/material';
import styled from 'styled-components';

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;
  max-width: 800px;

  ${({ theme }) => theme.breakpoints.up('xs')} {
    padding: 15px;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 0 35px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 98px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
`;

export const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 64px;
  width: 100%;
`;

export const ButtonStyled = styledMui(Button)<ButtonProps>(() => ({
  height: 42,
  borderRadius: 4,
}));

export const TextFieldStyled = styledMui(TextField)<TextFieldProps>(() => ({}));
