import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import DashboardLayout from '../layout/dashboard';
import LogoOnlyLayout from '../layout/only-logo-layout';

import Index from '../pages/app/index';
import Login from '../pages/auth/login/login';
import RecoverPassword from '../pages/recover-password';
import NotFound from '../pages/404';
import Register from '../pages/auth/register/register';
import { InstanceListScreen } from '../pages/app/instance/instance-list.screen';
import { RootState } from '../store';
import { InstanceDetailsScreen } from '../pages/app/instance/instance-details.screen';
import { MyAccountScreen } from '../pages/app/my-account.screen';

// import_screen

const AppRoutes = () => { 
  const { authUser, token } = useSelector((state: RootState) => state.user);

  const IS_AUTHENTICATED = !!authUser && !!token;

  const AUTHENTICATED_ROUTES = {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { element: <Navigate to="/dashboard" replace /> },
      { path: '/dashboard', element: <Index /> },
      { path: '/', element: <Index /> },
      { path: '/instances', element: <InstanceListScreen /> },
      { path: '/instances/:instanceKey', element: <InstanceDetailsScreen /> },
      // use_screen
      { path: '*', element: <NotFound />},
    ]
  };

  const UNAUTHENTICATED_ROUTES = {
    path: '/',
    children: [
      { element: <Navigate to="/login" replace /> },
      { path: '', element: <Login /> },
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: 'recover-password', element: <RecoverPassword /> },
      { path: '*', element: <NotFound />},
    ]
  };

  return useRoutes([
    IS_AUTHENTICATED ? AUTHENTICATED_ROUTES : UNAUTHENTICATED_ROUTES,
  ]);
};

export default AppRoutes;