import React, { useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, ListItemText, ListItemIcon, ListItemButton, ListItemButtonProps, ListSubheader, Link } from '@mui/material';
import { SideBarConfig } from '../../layout/dashboard/sidebar-config';

const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
    ({ theme }) => ({
        ...theme.typography.overline,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(5),
        color: theme.palette.text.primary
    })
);

const ListItemStyle = styled((props: ListItemButtonProps) => <ListItemButton disableGutters {...props} />)(
    ({ theme }) => ({
        ...theme.typography.body2,
        height: 48,
        position: 'relative',
        textTransform: 'capitalize',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(2.5),
        color: theme.palette.text.secondary,
        '&:before': {
            top: 0,
            right: 0,
            width: 3,
            bottom: 0,
            content: "''",
            display: 'none',
            position: 'absolute',
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            backgroundColor: theme.palette.primary.main
        }
    })
);

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

interface Props {
    item: any,
    isShow: boolean,
    active(path: string): boolean,
}

const NavItem: React.FC<Props> = ({ item, isShow, active }) => {
    const theme = useTheme();
    const isActiveRoot = active(item.path);
    const { title, path, icon, info, isRedirect } = item;
    const [open, setOpen] = useState(isActiveRoot);

    const activeRootStyle = {
        color: 'primary.main',
        fontWeight: 'fontWeightMedium',
        bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        '&:before': { display: 'block' }
    };

    if (isRedirect) {
        return (
            <ListItemStyle
                sx={{
                    ...(isActiveRoot && activeRootStyle)
                }}
                onClick={() => {
                    window.open('https://docs.zapfy.me', '_blank');
                }}
            >
                <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                {isShow && (
                    <>
                        <ListItemText disableTypography primary={title} />
                        {info && info}
                    </>
                )}
            </ListItemStyle>
        )
    }

    return (
        <Link underline="none" component={RouterLink} to={path}>
            <ListItemStyle
                sx={{
                    ...(isActiveRoot && activeRootStyle)
                }}
            >
                <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                {isShow && (
                    <>
                        <ListItemText disableTypography primary={title} />
                        {info && info}
                    </>
                )}
            </ListItemStyle>
        </Link>
    );
}

interface SectionProps {
    navConfig: SideBarConfig
    isShow?: boolean,
}

const NavSection: React.FC<SectionProps> = ({ navConfig, isShow = true, ...other}) => {
    const { pathname } = useLocation();
    const match = (path: any) => pathname === path;

    return (
        <Box {...other}>
            {navConfig.map((list) => {
                const { subheader, items } = list;
                return (
                    <List key={subheader} disablePadding>
                        {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
                        {items.map((item) => (
                            <NavItem key={item.title} item={item} active={match} isShow={isShow} />
                        ))}
                    </List>
                );
            })}
        </Box>
    );
};

export default NavSection;
