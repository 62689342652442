import { LabelColor } from "../components/label";

export const instanceStatusLabelSanitalizer: Record<string, { label: string, color: LabelColor }> = {
    'connected': {
        label: 'Conectado',
        color: 'success',
    },
    'connecting': {
        label: 'Conectando',
        color: 'warning',
    },
    'disconnected': {
        label: 'Desconectado',
        color: 'error',
    },
    'null': {
        label: 'Desconectado',
        color: 'error',
    }
}