import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Grid, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LoadingButton } from '@mui/lab';

import { RootState } from '../../../store';
import { Page } from '../../../components';
import HeaderBreadcrumbs from '../../../components/header-breadcrumbs';
import { InstanceService } from '../../../services/instance.service';
import { Label } from '../../../components/label';
import { instanceStatusLabelSanitalizer } from '../../../sanitalizers/instance-status-label';
import Loading from '../../../components/loading';
import useInterval from '../../../hooks/useInterval';

export const InstanceDetailsScreen: React.FC = () => {
    const { loading, instance, qrCode, state } = useSelector((state: RootState) => state.instance);
    const [contact, setContact] = useState<{ id: string, name: string} | undefined>(undefined);
    const { instanceKey } = useParams();

    console.log('instance', instance);

    const fetchQr = async () => {
        InstanceService.fetchQrCode(instance?.key, instance?.token, instance?.source!, (isValid, err, c) => {
            if (!isValid) {
                toast(
                    err,
                    {
                        autoClose: 1500,
                        type: 'error',
                    }
                );
                return
            }
            setContact(c);
        });
    }

    useEffect(() => {
        InstanceService.eraseQr();
        fetchQr();
    }, []);

    useInterval(async () => {
        if (!loading?.fetchQr && state?.toLowerCase() !== 'connected' && instance?.key) {
            await fetchQr();
        }
    }, 60000);

    return (
        <Page title="Zapfy - Detalhes da instância">
            <Container maxWidth={false}>
                <HeaderBreadcrumbs
                    heading={`Detalhes da instância`}
                    links={[
                            {
                            name: 'dashboard',
                            href: '/dashboard',
                        },
                        {
                            name: 'instâncias',
                            href: '/instances'
                        },
                        {
                            name: 'detalhes da instância',
                            href: '/instances/:instanceId'
                        },
                    ]}
                    activeLast={false}
                />
                <Grid container flexDirection={'row'} mb={2} mt={2} spacing={2}>
                    <Grid item md={8} sm={12}>
                        <Card sx={{ p: 2 }}>
                            <Typography variant="h6" fontWeight={500} color={'text.default'}>
                                Dados da instância {instance?.name}
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>
                                Essas são suas informações para integração com esta instância, não compartilhe essas informações.
                            </Typography>
                            <Grid sx={{ mt: 2, p: 0 }}>
                                <Typography fontSize={14} mb={1} fontWeight={600} color="text.secondary">
                                    API da instância
                                </Typography>
                                <TextField
                                    fullWidth
                                    disabled
                                    type="text"
                                    placeholder="API da instância"
                                    variant='filled'
                                    value={`https://api.zapfy.me/v1/instance/${instance?.key}/token/${instance?.token}/message?type=text`}
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip title={'Copiar API da instância'}>
                                                <InputAdornment position="end">
                                                    <IconButton onClick={async () => {
                                                        await navigator.clipboard.writeText(`/v1/instances/${instance?.key}/token/${instance?.token}/message?type=text`);
                                                        toast(
                                                            'Copiado com sucesso!',
                                                            {
                                                                autoClose: 1500,
                                                                type: 'success',
                                                            }
                                                        );
                                                    }} edge="end">
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            </Tooltip>
                                        )
                                    }}
                                />
                                <Typography fontSize={14} mt={2} mb={1} fontWeight={600} color="text.secondary">
                                    Webhook url da instância
                                </Typography>
                                <TextField
                                    fullWidth
                                    disabled
                                    type="text"
                                    placeholder="API da instância"
                                    variant='filled'
                                    value={instance?.webhookUrl}
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip title={'Copiar webhook url'}>
                                                <InputAdornment position="end">
                                                    <IconButton onClick={async () => {
                                                        await navigator.clipboard.writeText(instance?.webhookUrl);
                                                        toast(
                                                            'Copiado com sucesso!',
                                                            {
                                                                autoClose: 1500,
                                                                type: 'success',
                                                            }
                                                        );
                                                    }} edge="end">
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            </Tooltip>
                                        )
                                    }}
                                />
                                <Grid container mt={1} flexDirection={'row'} spacing={2}>
                                    <Grid item md={6} sm={12}>
                                        <Typography fontSize={14} mb={1} fontWeight={600} color="text.secondary">
                                            KEY da instância
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            disabled
                                            type="text"
                                            placeholder="KEY da instância"
                                            variant='filled'
                                            value={instance?.key}
                                            InputProps={{
                                                endAdornment: (
                                                    <Tooltip title={'Copiar key da instância'}>
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={async () => {
                                                                await navigator.clipboard.writeText(instance?.key);
                                                                toast(
                                                                    'Copiado com sucesso!',
                                                                    {
                                                                        autoClose: 1500,
                                                                        type: 'success',
                                                                    }
                                                                );
                                                            }} edge="end">
                                                                <ContentCopyIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    </Tooltip>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <Typography fontSize={14} mb={1} fontWeight={600} color="text.secondary">
                                            Token da instância
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            disabled
                                            type="text"
                                            placeholder="Token da instância"
                                            variant='filled'
                                            value={instance?.token}
                                            InputProps={{
                                                endAdornment: (
                                                    <Tooltip title={'Copiar token da instância'}>
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={async () => {
                                                                await navigator.clipboard.writeText(instance?.token);
                                                                toast(
                                                                    'Copiado com sucesso!',
                                                                    {
                                                                        autoClose: 1500,
                                                                        type: 'success',
                                                                    }
                                                                );
                                                            }} edge="end">
                                                                <ContentCopyIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    </Tooltip>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Stack direction="row-reverse" mt={2} >
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        InstanceService.dispatch.toggleModal('edit', true);
                                    }}
                                >
                                    Editar instância
                                </Button>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item md={4} sm={12}>
                        <Card sx={{ p: 2 }}>
                            <Typography variant="h6" fontWeight={500} color={'text.default'}>
                                Conectar à instância
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>
                                Escaneie o código abaixo com o seu Whatsapp para conectar à instância e aguarde 30 segundos.
                            </Typography>
                            <Stack mt={2} flexDirection={'row'} justifyContent={'space-between'}>
                                <Typography fontSize={16} fontWeight={500} color={'text.default'}>
                                    Status:
                                </Typography>
                                <Label
                                    variant={'ghost'}
                                    color={instanceStatusLabelSanitalizer[String(state)?.toLowerCase()]?.color}
                                >
                                    {instanceStatusLabelSanitalizer[String(state)?.toLowerCase()]?.label || 'Aguardando gerar qr code'} 
                                </Label>
                            </Stack>

                            {(state === 'connected' && contact !== undefined) &&
                                <Stack>
                                    <Typography variant='body1' fontSize={14} fontWeight={500} color={'text.secondary'}>
                                        <Label variant='ghost' color={'info'}>Telefone: {contact?.id} - {contact?.name}</Label> 
                                    </Typography>
                                </Stack>
                            }

                            <Grid justifyContent={'center'} alignItems={'center'} item display='flex' flexDirection={'column'}>
                                {!loading?.fetchQr && <img style={{ marginTop: 15 }} src={qrCode} />}

                                {loading?.fetchQr && <Loading />}

                                {(state?.toLowerCase() === 'connected' && !loading?.fetchQr) && <img style={{ marginTop: 25 }} alt="register" src="/static/illustrations/undraw_mobile_re_q4nk.svg" />}

                            </Grid>
                            
                            {((state?.toLowerCase() === 'connected') && !loading?.fetchQr) &&
                                <LoadingButton 
                                    variant="contained"
                                    color='error'
                                    type="submit"
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    loading={loading?.logout}
                                    onClick={async () => {
                                        await InstanceService.logout(instance?.key, instance?.token, instance?.source!, async (isValid, err) => {
                                            if (!isValid) {
                                                toast(
                                                    err,
                                                    {
                                                        autoClose: 1500,
                                                        type: 'error',
                                                    }
                                                );
                                                return
                                            }
                                            toast(
                                                'Instância desconectada com sucesso.',
                                                {
                                                    autoClose: 1500,
                                                    type: 'success',
                                                }
                                            );

                                            await fetchQr();
                                        });
                                    }}
                                >
                                    Desconectar instância
                                </LoadingButton>
                            }
                            
                            {(state?.toLowerCase() !== 'connected' && !loading?.fetchQr && qrCode) &&
                                <LoadingButton 
                                    variant="contained"
                                    color='success'
                                    type="submit"
                                    sx={{ mt: 2, color: 'white' }}
                                    fullWidth
                                    onClick={async () => {
                                        await fetchQr();
                                    }}
                                >
                                   Recarregar
                                </LoadingButton>
                            }

                            {(!qrCode && !loading?.fetchQr) &&
                                <LoadingButton 
                                    variant="contained"
                                    color='success'
                                    type="submit"
                                    sx={{ mt: 2, color: 'white' }}
                                    fullWidth
                                    onClick={async () => {
                                        await fetchQr();
                                    }}
                                >
                                   Gerar QR Code
                                </LoadingButton>
                            }

                        </Card>
                    </Grid>
                </Grid>
               
            </Container>
        </Page>
    )
}