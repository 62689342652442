import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';

import { MotionContainer, varBounceIn } from '../components/animate';
import {
    Page,
} from '../components';

const RootStyle = styled(Page)(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10)
}));

export default function Page404() {
    return (
        <RootStyle title="Zapfy - Pagina não encontrada">
            <Container>
                <MotionContainer open>
                    <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
                        
                        <motion.div variants={varBounceIn}>
                           <Typography variant={'h1'}>
                            🔎
                           </Typography>
                        </motion.div>
                        <motion.div variants={varBounceIn}>
                            <Typography variant="h3" paragraph>
                                Desculpe, página não encontrada
                            </Typography>
                        </motion.div>
                        <Typography sx={{ mt: 2, color: 'text.secondary' }}>
                            A página que vocẽ está tentando acessar não existe.
                        </Typography>
                        <Button sx={{ mt: 3 }} fullWidth to="/" size="large" variant="contained" component={RouterLink}>
                            Voltar
                        </Button>
                    </Box>
                </MotionContainer>
            </Container>
        </RootStyle>
    );
}