import React from 'react';  
import { Box, Link, Stack, Typography } from '@mui/material';

import {
    RegisterForm,
} from '../../../components';
import LoginLayout from '../layout/auth-layout';
import * as S from '../styles';
  
const Register: React.FC = () => {
    return (
        <LoginLayout>
            
            <S.LoginContainer>
                
                <Stack direction="row" alignItems="center">
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h5">
                            Criar conta na ZapFy
                        </Typography>
                        <Typography color="textSecondary" mt="10px" variant="subtitle2">
                            Informe todos os dados solicitados abaixo, certifique-se de que todos os dados inseridos estejam corretos.
                        </Typography>
                    </Box>
                </Stack>
                <S.FormContainer>
                    <RegisterForm />
                    <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
                        Ao se register, você estará concordando com O &nbsp;
                        <Link underline="always" sx={{ color: 'text.primary' }}>
                            Termos de Uso
                        </Link>
                        &nbsp;e&nbsp;
                        <Link underline="always" sx={{ color: 'text.primary' }}>
                            Termos e Privacidade
                        </Link>
                        .
                    </Typography>
                </S.FormContainer>
            </S.LoginContainer>
        </LoginLayout>
    )
}

export default Register;