import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import InventoryIcon from '@mui/icons-material/SmartphoneOutlined';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import Person from '@mui/icons-material/Person';

const sidebarConfig = [
  // declare_menu_module
  {
    subheader: 'Geral',
    items: [
      {
        title: 'Dashboard',
        path: '/dashboard',
        icon: <ViewKanbanIcon />,
      },
      {
        title: 'Minhas intâncias',
        path: '/instances',
        icon: <InventoryIcon />,
      },
    ]
  },
  {
    subheader: 'Informativo',
    items: [
      {
        title: 'Documentação',
        path: '',
        isRedirect: true,
        icon: <HistoryEduIcon />
      }
    ]
  }
];

export type SideBarConfig = typeof sidebarConfig;

export default sidebarConfig;