import { AxiosError, AxiosResponse } from 'axios';
import qs from 'qs-stringify';

import { RESPONSE_CODES } from '../constants/responseCodes.constants';
import { PaginationFactory, PaginatorFactory } from '../factory';
import { RegexFactory } from '../factory/regex.factory';
import { api } from '../http';
import { store } from '../store';
import { InstanceStoreProps } from '../store/instance.store';
import { DefaultRes, InstanceTypes } from '../types';
import { instanceMetricPeriodConstant } from '../constants/instance-metric-period.constant';
import { intanceSourceResolver } from '../constants/instance-source.constant';

export class InstanceService {
    public static readonly dispatch = store.dispatch.instance;

    public static eraseQr() {
        const { dispatch } = InstanceService;

        dispatch.eraseQr();
    }

    public static async fetchMetrics(
        instances?: string,
        period?: string,
        callback?: (
            isValid?: boolean,
            message?: string,
        ) => void,
    ) {
        const { dispatch: { toggleLoading, setMetric } } = InstanceService;

        try {
            toggleLoading('fetchMetrics', true);

            let baseUrl = `/user/getInstanceMetrics`;

            if (instances && instances !== 'all') {
                baseUrl += `?instances=${instances}`;
            }

            if (period && period !== 'this_month') {
                const date = instanceMetricPeriodConstant[period];
                
                baseUrl += instances && instances !== 'all' ? 
                    `&initialDate=${date?.initialDate}&finalDate=${date?.finalDate}` :
                    `?initialDate=${date?.initialDate}&finalDate=${date?.finalDate}`;
            }

            const { data: { result: { items, resumeMetrics }, isValid, message }} = await api.get<
                DefaultRes<InstanceTypes.FetchMetrics>,
                AxiosResponse<DefaultRes<InstanceTypes.FetchMetrics>>
            >(baseUrl);

            toggleLoading('fetchMetrics', false);

            if (isValid) {
                setMetric({ items, resumeMetrics });
            }
            
            return callback && callback(isValid, RESPONSE_CODES[message]);

        } catch (error: any) {
            toggleLoading('fetchMetrics', false);
            if (error.response) {
                const ERROR_CODE = (error as AxiosError<
                    DefaultRes<InstanceTypes.FetchMetrics>
                >).response?.data?.message;

                return callback && callback(false, RESPONSE_CODES[ERROR_CODE!]);
            }
            return callback && callback(false, String(error));
        }
    }

    public static async fetchQrCode(
        key: string, 
        token: string,
        source: string,
        callback?: (
            isValid?: boolean,
            message?: string,
            contact?: { id: string, name: string },
        ) => void,
    ){
        const { dispatch: { toggleLoading, setQr, setState } } = InstanceService;

        console.log('source', source);

        try {
            toggleLoading('fetchQr', true);

            const { data: { result, isValid, message }} = await intanceSourceResolver[source].post<
                DefaultRes<InstanceTypes.FetchQrCode>,
                AxiosResponse<DefaultRes<InstanceTypes.FetchQrCode>>
            >(`/instance/${key}/token/${token}/connect`);

            toggleLoading('fetchQr', false);

            if (isValid) {
                setQr(result?.qrCode);
                setState(result?.state);
            }

            if (result?.contact) {
                const contact = {
                    id: RegexFactory.phoneNumber(result?.contact?.id!),
                    name: result?.contact?.name!,
                }
    
                return callback && callback(isValid, RESPONSE_CODES[message], contact);
            }

            return callback && callback(isValid, RESPONSE_CODES[message], undefined);
        } catch (error: any) {
            toggleLoading('fetchById', false);
            if (error.response) {
                const ERROR_CODE = (error as AxiosError<
                    DefaultRes<{ qrCode: string, state: string }>
                >).response?.data?.message;

                return callback && callback(false, RESPONSE_CODES[ERROR_CODE!]);
            }
            return callback && callback(false, String(error));
        }
    }

    public static async logout(
        key: string, 
        token: string,
        source: string,
        callback?: (isValid?: boolean, message?: string) => void,
    ){
        const { dispatch: { toggleLoading, setQr, setState } } = InstanceService;

        try {
            toggleLoading('logout', true);

            const { data: { result, isValid, message }} = await intanceSourceResolver[source].post<
                DefaultRes<InstanceTypes.LogoutInstance>,
                AxiosResponse<DefaultRes<InstanceTypes.LogoutInstance>>
            >(`/instance/${key}/token/${token}/disconnect`);

            toggleLoading('logout', false);
            
            if (isValid) {
                setQr(result?.qrCode);
                setState(result?.state);
            }

            return callback && callback(isValid, RESPONSE_CODES[message]);
        } catch (error: any) {
            toggleLoading('logout', false);
            if (error.response) {
                const ERROR_CODE = (error as AxiosError<
                    DefaultRes<{ qrCode: string, state: string }>
                >).response?.data?.message;

                return callback && callback(false, RESPONSE_CODES[ERROR_CODE!]);
            }
            return callback && callback(false, String(error));
        }
    }

    public static async fetchByKey(
        key: string, 
        callback?: (isValid?: boolean, message?: string) => void,
    ){
        const { dispatch: { toggleLoading, set, setQr, setState } } = InstanceService;

        try {
            set({} as InstanceTypes.Model);
            setQr('');
            setState('');
            toggleLoading('fetchById', true);

            const { data: { result, isValid, message }} = await api.get<
                DefaultRes<InstanceTypes.Model>,
                AxiosResponse<DefaultRes<InstanceTypes.Model>>
            >(`/user/getMineInstanceByKey/${key}`);

            toggleLoading('fetchById', false);
            
            if (isValid) {
                set(result);
            }

            return callback && callback(isValid, RESPONSE_CODES[message]);
        } catch (error: any) {
            toggleLoading('fetchById', false);
            if (error.response) {
                const ERROR_CODE = (error as AxiosError<
                    DefaultRes<InstanceTypes.Model>
                >).response?.data?.message;

                return callback && callback(false, RESPONSE_CODES[ERROR_CODE!]);
            }
            return callback && callback(false, String(error));
        }
    }

    public static async getMineInstancesReport() {
        const { dispatch: { toggleLoading, setReport } } = InstanceService;

        try {
            toggleLoading('getMineInstancesReport', true);

            const { data: { result: { connected, disconnected, total, updatedAt  } }} = await api.get<
                DefaultRes<InstanceStoreProps['report']>,
                AxiosResponse<DefaultRes<InstanceStoreProps['report']>>
            >(`/user/getMineInstancesReport`);

            toggleLoading('getMineInstancesReport', false);

            setReport({ connected, disconnected, total, updatedAt });
        } catch (error: any) {
            toggleLoading('getMineInstancesReport', false);
            if (error.response) {
                const ERROR_CODE = (error as AxiosError<
                    DefaultRes<InstanceTypes.Model>
                >).response?.data?.message;
            }
        }
    }

    public static async fetchMany(
        dto: PaginatorFactory<{}>,
        callback?: (isValid?: boolean, message?: string) => void,
    ): Promise<PaginationFactory<InstanceTypes.Model> | void> {
        const { dispatch: { toggleLoading, setPagination } } = InstanceService;

        try {
            toggleLoading('fetchMany', true);

            const qsfy = qs({
                perPage: dto.perPage,
                page: dto?.page,
                filter: {
                    ...dto?.filter,
                },
            });

            const { data: { result: { items, page, perPage, total }, isValid, message }} = await api.get<
                DefaultRes<PaginationFactory<InstanceTypes.Model>>,
                AxiosResponse<DefaultRes<PaginationFactory<InstanceTypes.Model>>>
            >(`/user/getMineInstances?${qsfy}`);

            toggleLoading('fetchMany', false);

            setPagination({ items, page, perPage, total });

            callback && callback(isValid, RESPONSE_CODES[message]);

            return { items, page, perPage, total };
        } catch (error: any) {
            toggleLoading('fetchMany', false);
            if (error.response) {
                const ERROR_CODE = (error as AxiosError<
                    DefaultRes<InstanceTypes.Model>
                >).response?.data?.message;

                callback && callback(false, RESPONSE_CODES[ERROR_CODE!]);
            }
            callback && callback(false, String(error));
        }
    }

    public static async create(
        name: string,
        webhookUrl: string,
        callback?: (isValid?: boolean, message?: string) => void,
    ){
        const { dispatch: { toggleLoading, add } } = InstanceService;

        try {
            toggleLoading('create', true);

            const { data: { isValid, message, result }} = await api.post<
                DefaultRes<InstanceTypes.Model>,
                AxiosResponse<DefaultRes<InstanceTypes.Model>>
            >(`/user/createInstance`, { name, webhookUrl });

            toggleLoading('create', false);

            if (isValid) {
                add(result);
            }

            return callback && callback(isValid, RESPONSE_CODES[message]);
        } catch (error: any) {
            toggleLoading('create', false);
            if (error.response) {
                const ERROR_CODE = (error as AxiosError<
                    DefaultRes<InstanceTypes.Model>
                >).response?.data?.message;

                return callback && callback(false, RESPONSE_CODES[ERROR_CODE!]);
            }
            return callback && callback(false, String(error));
        }
    }

    public static async edit(
        name: string,
        webhookUrl: string,
        instanceKey: string,
        instanceToken: string,
        source: string,
        callback?: (isValid?: boolean, message?: string) => void,
    ){
        const { dispatch: { toggleLoading, set } } = InstanceService;

        try {
            toggleLoading('update', true);

            const apiService = source ? intanceSourceResolver[source] : api;

            const { data: { isValid, message, result }} = await apiService.post<
                DefaultRes<InstanceTypes.Model>,
                AxiosResponse<DefaultRes<InstanceTypes.Model>>
            >(`/instance/${instanceKey}/token/${instanceToken}/updateWebhook`, { name, webhookUrl });

            toggleLoading('update', false);

            if (isValid) {
                set(result);
            }

            return callback && callback(isValid, RESPONSE_CODES[message]);
        } catch (error: any) {
            toggleLoading('update', false);
            if (error.response) {
                const ERROR_CODE = (error as AxiosError<
                    DefaultRes<InstanceTypes.Model>
                >).response?.data?.message;

                return callback && callback(false, RESPONSE_CODES[ERROR_CODE!]);
            }
            return callback && callback(false, String(error));
        }
    }
};