import React from 'react';

interface Props {
    sx?: Object,
};

const Logo: React.FC<Props> = ({ sx }) => {
    return <img src="/static/illustrations/logo.png" style={{ width: 124, height: 100 }}  />;
}

export default Logo;    