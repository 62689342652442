import { AxiosInstance } from "axios";

import { api, apiMilvus, apiYooga, apiBc, apiMf } from "../http";

export const intanceSourceResolver: Record<string, AxiosInstance> = {
    core: api,
    milvus: apiMilvus,
    yooga: apiYooga,
    bomcontrole: apiBc,
    mf: apiMf
}