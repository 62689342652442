import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from 'react-redux';
import { getPersistor } from '@rematch/persist';
import * as dayjs from 'dayjs';
import FloatingWhatsApp from 'react-floating-whatsapp';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider } from '@mui/material/styles';

import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import AppRoutes from './routes';
import { store } from './store';
import { customTheme } from './theme/css/mui';
import React from 'react';
import TagManager from 'react-gtm-module'

dayjs.locale('pt-BR')

const persistor = getPersistor();

const tagManagerArgs = {
  gtmId: 'GTM-WWG9T8C',
}

TagManager.initialize(tagManagerArgs);

function App() {
  const theme = React.useMemo(() => customTheme, [customTheme]);
  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>
            <ThemeConfig>
              <GlobalStyles />
              <AppRoutes />
              <ToastContainer />
            </ThemeConfig>
          </StyledThemeProvider>
        </ThemeProvider>
      </Provider>
    </PersistGate>
  );
}

export default App;
