import dayjs from "dayjs";

export const instanceMetricPeriodConstant: Record<string, {initialDate: Date, finalDate: Date }> = {
    last_month: {
        initialDate: dayjs().subtract(1, 'month').startOf('month').toDate(),
        finalDate: dayjs().subtract(1, 'month').endOf('month').toDate(),
    },
    yesterday: {
        initialDate: dayjs().subtract(1, 'day').toDate(),
        finalDate: dayjs(new Date()).toDate(),
    },
    today: {
        initialDate: dayjs(new Date()).startOf('day').toDate(),
        finalDate: dayjs(new Date()).toDate(),
    },
};