import { createTheme } from '@mui/material';

import { enUS as coreEnUS } from '@mui/material/locale';
import { enUS } from '@mui/x-data-grid';
import { enUS as pickersEnUs } from '@mui/x-date-pickers';

interface ColorVariants {
  main: string;
  light: string;
  dark: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    whiteLight: ColorVariants;
    grayLighter: ColorVariants;
    grayLight: ColorVariants;
    grayRegular: ColorVariants;
    grayMedium: ColorVariants;
    black: ColorVariants;
  }
  interface PaletteOptions {
    whiteLight: ColorVariants;
    grayLighter: ColorVariants;
    grayLight: ColorVariants;
    grayRegular: ColorVariants;
    grayMedium: ColorVariants;
    black: ColorVariants;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    whiteLight: true;
    grayLighter: true;
    grayLight: true;
    grayRegular: true;
    grayMedium: true;
    black: true;
  }
}

type MyTheme = typeof customTheme;

/* eslint-disable */
declare module 'styled-components' {
  export interface DefaultTheme extends MyTheme {}
}

export const lightTheme = createTheme(
  {
    palette: {
      mode: 'light',
      common: {
        black: '#272937',
        white: '#FFFFFF',
      },
      divider: 'rgba(0, 0, 0, 0.12)',
      primary: {
        main: '#0F90FF',
        light: '#007DFF',
        dark: '#1976D2',
      },
      success: {
        main: '#00E699  ',
        light: '#4CAF50',
        dark: '#2E7D32',
      },
      error: {
        main: '#D32F2F',
        light: '#D32F2F',
        dark: '#D32F2F',
      },
      warning: {
        main: '#F3BA2F',
        light: '#FF9800',
        dark: '#ED6C02',
      },
      info: {
        main: '#0288D1',
        light: '#0288D1',
        dark: '#0288D1',
      },
      whiteLight: {
        main: '#F8F9FB',
        light: '#FAFAFA',
        dark: '#BDBDBD',
      },
      grayLighter: {
        main: '#F3F4F9',
        light: '#F3F4F9',
        dark: '#F3F4F9',
      },
      grayLight: {
        main: '#C3C6DB',
        light: '#C3C6DB',
        dark: '#C3C6DB',
      },
      grayRegular: {
        main: '#9EA1B9',
        light: '#9EA1B9',
        dark: '#9EA1B9',
      },
      grayMedium: {
        main: '#757893',
        light: '#757893',
        dark: '#757893',
      },
      black: {
        main: '#272937',
        light: '#272937',
        dark: '#272937',
      },
    },
    shape: {
      borderRadius: 4,
    },
  },
  enUS,
  pickersEnUs,
  coreEnUS
);

export const darkTheme = createTheme(
  {
    palette: {
      mode: 'dark',
      background: {
        default: '#1E1E1E',
        paper: '#2A2A2A',
      },
      text: {
        primary: '#FFFFFF',
        secondary: '#B0B0B0',
      },
      common: {
        black: '#FFFFFF',
        white: '#272937',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      primary: {
        main: '#2E81C2',
        light: '#72B3E3',
        dark: '#1F6A9D',
      },
      success: {
        main: '#4caf50',
        light: '#80e27e',
        dark: '#087f23',
      },
      error: {
        main: '#f44336',
        light: '#ff7961',
        dark: '#ba000d',
      },
      warning: {
        main: '#ff9800',
        light: '#ffc947',
        dark: '#c66900',
      },
      info: {
        main: '#2196f3',
        light: '#64b5f6',
        dark: '#0069c0',
      },
      whiteLight: {
        main: '#333333',
        light: '#5f5f5f',
        dark: '#1a1a1a',
      },
      grayLighter: {
        main: '#222222',
        light: '#4f4f4f',
        dark: '#000000',
      },
      grayLight: {
        main: '#444444',
        light: '#6d6d6d',
        dark: '#1b1b1b',
      },
      grayRegular: {
        main: '#666666',
        light: '#999999',
        dark: '#333333',
      },
      grayMedium: {
        main: '#888888',
        light: '#bbbbbb',
        dark: '#555555',
      },
      black: {
        main: '#FFFFFF',
        light: '#CCCCCC',
        dark: '#FFFFFF',
      },
    },
    shape: {
      borderRadius: 4,
    },
    typography: {
      h1: {
        color: '#FFFFFF',
      },
      h2: {
        color: '#FFFFFF',
      },
      h3: {
        color: '#FFFFFF',
      },
      h4: {
        color: '#FFFFFF',
      },
      h5: {
        color: '#FFFFFF',
      },
      h6: {
        color: '#FFFFFF',
      },
      body1: {
        color: '#FFFFFF',
      },
      body2: {
        color: '#FFFFFF',
      },
      subtitle1: {
        color: '#FFFFFF',
      },
      subtitle2: {
        color: '#FFFFFF',
      },
      caption: {
        color: '#FFFFFF',
      },
      overline: {
        color: '#FFFFFF',
      },
      button: {
        color: '#FFFFFF',
      },
    },
  },
  enUS,
  pickersEnUs,
  coreEnUS
);

export const isDarkMode = window.localStorage.getItem('theme') === 'dark';
export const customTheme = isDarkMode ? darkTheme : lightTheme;
