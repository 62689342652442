import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Card, Grid, Typography, Chip, Select, MenuItem, OutlinedInput } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { InstanceService } from '../../services/instance.service';
import { RootState } from '../../store';

export const InstanceMetrics = ({ }) => {
    const { pagination: { items }, metrics } = useSelector((state: RootState) => state.instance);
    const [period, setPeriod] = useState('this_month');
    const [selectedInstance, setSelectedInstance] = useState('all');
    const [total, setTotal] = useState({ sent: 0, received: 0 });
    const theme = useTheme();

    const periods = [
        {
            value: 'this_month',
            label: 'Este mês',
        },
        {
            value: 'last_month',
            label: 'Mês passado',
        },
        {
            value: 'yesterday',
            label: 'Ontem',
        },
        {
            value: 'today',
            label: 'Hoje',
        },
    ];

    useEffect(() => {
        InstanceService.fetchMany({ page: 1, perPage: 500, filter: {} });
    }, []);

    useEffect(() => {
        InstanceService.fetchMetrics(selectedInstance, period);
    }, [selectedInstance, period]);

    useEffect(() => {
        if (metrics?.resumeMetrics) {
            setTotal({
                sent: (
                    (metrics?.resumeMetrics?.textMsgSent || 0) + 
                    (metrics?.resumeMetrics?.locationMsgSent || 0) + 
                    (metrics?.resumeMetrics?.buttonsMsgSent || 0)
                ),
                received: (
                    (metrics?.resumeMetrics?.textMsgReceived || 0) +
                    (metrics?.resumeMetrics?.locationMsgReceived || 0) +
                    (metrics?.resumeMetrics?.buttonsMsgReceived || 0) +
                    (metrics?.resumeMetrics?.listMsgReceived || 0) +
                    (metrics?.resumeMetrics?.stickerMsgReceived || 0) +
                    (metrics?.resumeMetrics?.imagemMsgReceived || 0) +
                    (metrics?.resumeMetrics?.videoMsgReceived || 0) +
                    (metrics?.resumeMetrics?.audioMsgReceived || 0) +
                    (metrics?.resumeMetrics?.documentMsgReceived || 0) +
                    (metrics?.resumeMetrics?.contactMsgReceived || 0)
                )
            });
        }
    }, [metrics]);

    console.log('selectedInstance', selectedInstance);

    return (
        <Grid container display="flex" flexDirection="row" spacing={1} sx={{ mt: 1 }}>
            <Grid item md={8} sm={12} width={'100%'} mt={1}>
                <Card sx={{ p: 2 }}>
                    <Grid container display="flex" flexDirection="row" sx={{ mb: 1 }}>
                        <Grid item md={8}>
                            <Typography fontWeight={500} >
                                Instância
                            </Typography>
                        </Grid>
                        <Grid item md={4}>
                            <Typography fontWeight={500}>
                                Período
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container display="flex" flexDirection="row" spacing={1}>
                        <Grid item md={8} xs={12}>
                            <Select
                                labelId="instance-select-label"
                                id="instance-select"
                                fullWidth
                                multiple={false}
                                value={selectedInstance}
                                onChange={(e) => setSelectedInstance(e.target.value)}
                                sx={{ mt: 0.5 }}
                                size="small"
                                input={<OutlinedInput label="Name" />}
                            >
                                <MenuItem
                                    key={'all'}
                                    value={'all'}
                                >
                                    Todas
                                </MenuItem>
                                {items.map((instance) => (
                                    <MenuItem
                                        key={instance?.id}
                                        value={instance?.id}
                                    >
                                        {instance?.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                            
                        <Grid item md={4}>
                            <Grid container display="flex" flexDirection="row">
                                {periods?.map((pe) => (
                                    <Chip 
                                        key={`chip-${pe?.value}`} 
                                        size={'small'} 
                                        label={pe?.label}
                                        onClick={() => setPeriod(pe?.value)}
                                        sx={{ mr: 0.5, mt: 0.3 }}
                                        variant={period === pe?.value ? 'filled' : 'outlined'} 
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item md={4} sm={12} width={'100%'} mt={1}>
                <Grid container display="flex" flexDirection="row" spacing={1}>
                    <Grid item md={6} sm={12} width={'100%'}>
                        <Card sx={{ borderRadius: 1 }}>
                            <Box 
                                display={'flex'} 
                                flexDirection="column" 
                                borderRadius={1} 
                                sx={{ 
                                    backgroundImage: `linear-gradient(135deg,
                                    ${theme.palette.primary.main} 0%,
                                    ${theme.palette.primary.main} 100%)`, 
                                    p: 2,
                                    color: 'white'
                                }} 
                                alignItems={'center'} 
                                justifyContent={'center'}
                            >
                                <Typography textAlign={'center'}  component="div" variant="subtitle2">
                                    Mensagens enviadas
                                </Typography>
                                <Typography mt={1} textAlign={'center'} fontSize={18} fontWeight={700}>
                                    {total?.sent}
                                </Typography>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item md={6} sm={12} width={'100%'}>
                        <Card sx={{ borderRadius: 1 }}>
                            <Box 
                                display={'flex'} 
                                flexDirection="column" 
                                borderRadius={1} 
                                sx={{ 
                                    backgroundImage: `linear-gradient(135deg,
                                    ${theme.palette.warning.main} 0%,
                                    ${theme.palette.warning.dark} 180%)`, 
                                    p: 2,
                                    color: 'white'
                                }} 
                                alignItems={'center'} 
                                justifyContent={'center'}
                            >
                                <Typography textAlign={'center'}  component="div" variant="subtitle2">
                                    Mensagens recebidas
                                </Typography>
                                <Typography mt={1} textAlign={'center'} fontSize={18} fontWeight={700}>
                                    {total?.received}
                                </Typography>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}